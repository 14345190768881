import React from "react";
import "./App.css";
import "../src/assets/styles/index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Navbar from "./components/Navbar";
import Header from "./components/Header";

function App() {
  return (
    <div className="contain-components">
      {/* <img
        className="bg-img"
        src="https://mememaniadapp.netlify.app/images/background.png"
        alt=""
      /> */}
      {/* <video className="bg-video" autoPlay loop muted>
        <source
          src="https://app.cultdao.io/videos/Stormy-Sky2_background.mp4"
          type="video/mp4"
        />
      </video> */}
      <Navbar />
      <Header />
    </div>
  );
}

export default App;
