import img from "../assets/imgs/header.jpg";
// import "../compnentsCss/header.css";
import { Row, Col, Button } from "reactstrap";
const Header = () => {
  return (
    // <div className="container">
    <div className="header">
      <div className="text-center img-wrapper">
        <img src={img} alt="" className="img-fluid" />
      </div>
      <div className="text-center mt-5">
        <Button>Mint</Button>
      </div>
      <Row className="justify-content-center social-links gap-3">
        <Col xs="auto">
          <i className="fa-brands fa-twitter fa-lg"></i>
        </Col>
        <Col xs="auto">
          <i className="fa-brands fa-discord fa-sm"></i>
        </Col>
        <Col xs="auto">
          <i className="fa-brands fa-telegram fa-lg"></i>
        </Col>
        <Col xs="auto">
          <i className="fa-brands fa-twitter fa-lg"></i>
        </Col>
        <Col xs="auto">
          <i className="fa-brands fa-twitter fa-lg"></i>
        </Col>
      </Row>
    </div>
    // </div>
  );
};

export default Header;
