import { useState, useEffect } from "react";
import "../compnentsCss/nav-bar.css";
import { Button, Col, Row } from "reactstrap";

const Navbar = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 800);
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Initial check
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className="Nav-bar">
      {isSmallScreen ? (
        <Row className="navbar-row-small justify-content-between align-items-center">
          <Col xs="auto">
            <img
              src="https://mememaniadapp.netlify.app/images/CultDaoLogo.png"
              width={150}
              alt=""
            />
          </Col>
          <Col xs="auto">
            <Row className="gap-4 align-items-center">
              <Col>
                <p className="m-0 text__primary">Connect</p>
              </Col>
            </Row>
          </Col>
        </Row>
      ) : (
        <Row className="justify-content-between align-items-center navbar-row navbar-row-large">
          <Col xs="auto">
            <Button className="logo-button">
              <img
                src="https://mememaniadapp.netlify.app/images/CultDaoLogo.png"
                width={150}
                alt=""
              />
            </Button>
          </Col>

          <Col xs="auto">
            <Button className="connect-button">
              <p className="text__primary">Connect</p>
            </Button>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default Navbar;
